// Imports: Dependencies
import * as React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'react-bootstrap';

// Imports: Components
import SEO from '../components/seo';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Layout from '../layouts/Layout';

// Page: About
const About: React.FC<PageProps> = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="About" />

      <NavBar />

      <div id="profile-content">
        <h1 id="profile-title">About Me</h1>

        <Row id="me-container">
          <Col xs={12} sm={12} md={6} lg={6} id="me-description-container">
            <p id="me-description">
              I'm a Full Stack TypeScript/JavaScript developer, <b>specializing in React (Progressive Web Apps) and React Native (iOS/Android Apps)</b>.
            </p>

            <p id="me-description">
              I'm an alumni from the University of California, Irvine, where I received my Bachelors in Business Administration with an emphasis in Accounting and Finance.
            </p>

            <p id="me-description">
              I then attended Hack Reactor Los Angeles for the Advanced Software Engineering Immersive, where I learned Full Stack JavaScript development (MongoDB, Express, React, Node.js, HTML, CSS), pair programming, data structures, and algorithms.
            </p>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6}  id="me-image-container">
            <StaticImage src="../images/DSC00144.jpeg" alt="A dinosaur" />
          </Col>
        </Row>

        <h1 id="profile-title">Creative</h1>

        <Row id="video-edits-container">
          <Col xs={12} sm={12} md={6} lg={6} id="video-edits-description-container">
            <p id="me-description">
              I learned video editing through using iMovie for school projects over 10 years ago and have been making video edits ever since.
              During highschool, I was in video production for 2 years and learned Final Cut Pro 7.
              Now Final Cut Pro X is my preferred suite for video editing.
            </p>

            <p id="me-description">
              Between capturing videos of me or my friends charging waves, getting deep in barrels, on the mountain, or footage from trips, editing has been a creative outlet for me.
              If the waves are pumping or if there's snow on the mountain, I usually have my GoPro with me.
            </p>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} id="youtube-container">
            <div className="video-container">
              <iframe
                title="the-tens"
                height="800"
                src="https://www.youtube.com/embed/bBuDU45gGmk"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: 7 }}
              >
              </iframe>
            </div>

            <div className="video-container">
              <iframe
                title="submerged-perspective"
                height="800"
                src="https://www.youtube.com/embed/4b2X1IO_Zuc"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: 7 }}
              >
              </iframe>
            </div>
          </Col>
        </Row>

        <h1 id="profile-title">What I Like To Do</h1>

        <Row id="bodyboard-container">
          <Col xs={12} sm={12} md={6} lg={6} id="bodyboard-description-container">
            <p id="me-description">
              Growing up in Orange County, I've been fortunate to have quick access to the beach.
              I spend most of my free time in what I call paradise.
            </p>

            <p id="me-description">
              You can find me with my friends catching waves in Laguna Beach, Dana Point, Newport Beach, or San Clemente.
              If it's riding a bodyboard, bodysurfing, stand up bodyboarding, surfing, or paddleboarding, I'm in.
            </p>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} id="youtube-container">
            <div className="video-container">
              <iframe
                title="boadyboard-video-1"
                height="800"
                src="https://www.youtube.com/embed/2HBK88Fk8b8"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: 7 }}
              >
              </iframe>
            </div>

            <div className="video-container">
              <iframe
                title="boadyboard-video-2"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/rPswgOPo0BA"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: 7 }}
              >
              </iframe>
            </div>
          </Col>
        </Row>

        <div style={{ marginTop: 32 }}>
          <h1 id="profile-title">Favorite Books</h1>
        </div>

        <Row id="book-container">
          <Col xs={4} sm={4} md={4} lg={4} id="book-column-container">
            <a href="https://www.amazon.com/Insanely-Simple-Obsession-Drives-Success/dp/1591844835/" target="_blank" rel="noopener noreferrer">
              <div className="book-image-container">
                <StaticImage src="../images/InsanelySimple.jpg" alt="A dinosaur" />
              </div>
            </a>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4} id="book-column-container">
            <a href="https://www.amazon.com/Algorithms-Live-Computer-Science-Decisions-dp-1627790365/dp/1627790365/" target="_blank" rel="noopener noreferrer">
              <div className="book-image-container">
                <StaticImage src="../images/AlgorithmsToLiveBy.jpg" alt="A dinosaur" />
              </div>
            </a>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4}  id="book-column-container">
            <a href="https://www.amazon.com/Zero-One-Notes-Startups-Future/dp/0804139296/" target="_blank" rel="noopener noreferrer">
              <div className="book-image-container">
                <StaticImage src="../images/ZeroToOne.jpg" alt="A dinosaur" />
              </div>
            </a>
          </Col>
        </Row>

        <Row id="book-container">
          <Col xs={4} sm={4} md={4} lg={4}  id="book-column-container">
            <a href="https://www.amazon.com/Rework-Jason-Fried/dp/0307463745/" target="_blank" rel="noopener noreferrer">
              <div className="book-image-container">
                <StaticImage src="../images/Rework.jpg" alt="A dinosaur" />
              </div>
            </a>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4}  id="book-column-container">
            <a href="https://www.amazon.com/Smart-People-Should-Build-Things/dp/0062292048" target="_blank" rel="noopener noreferrer">
              <div className="book-image-container">
                <StaticImage src="../images/SmartPeople.jpg" alt="A dinosaur" />
              </div>
            </a>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4}  id="book-column-container">
            <a href="https://www.amazon.com/Outliers-Story-Success-Malcolm-Gladwell/dp/0316017930/" target="_blank" rel="noopener noreferrer">
              <div className="book-image-container">
                <StaticImage src="../images/Outliers.jpeg" alt="A dinosaur" />
              </div>
            </a>
          </Col>
        </Row>

        <div style={{ marginBottom: 120 }}></div>
      </div>

      <Footer />
    </Layout>
  );
};

// Exports
export default About;
